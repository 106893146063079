@import '../../../assets/scss/partials/vars';

// z-index is higher than <Backdrop /> component.
#modal-wrapper {
    z-index: 4;
    position: fixed;
    top: calc(50vh - 25vh);
    left: calc(50vw - 200px); 
    width: 400px;
    transition: all 0.8s linear;
    //width: 100%;
   
    .card {
        z-index: 3;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        //height: 400px;
        width: 90%;
        margin: 0 auto;
        padding-top: 50px;
        padding-bottom: 50px;
        background: $spade-blue;
        border-radius: 1rem;
        text-align: center;
        opacity: 0.9;
        
        @media(min-width: $screen-md) {
            width: 400px;
        }
    }

}
