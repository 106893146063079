@import 'assets/scss/partials/vars';

.blockwrap.logos-content {
    background: $spade-cobalt-blue;

    .content {
        display: flex;
    }

    .logos-wrap {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        margin: 15px auto;
        padding: 35px 0 20px 0;
        border-top: 1px solid $off-white;
        border-bottom: 1px solid $off-white;
        gap: 25px;

        @media(min-width: $screen-md){

        }

        .logo_holder {
            flex-basis: 25%;
            // min-width: 120px;
            display: flex;
            justify-content: center;
        }

        .logo {
            display: block;
            max-width: 150px;
            max-height: 45px;
            width: auto;
            margin: 10px auto;
            color: white;


            @media(min-width: $screen-md){
                margin: 0 auto;
            }
        }

    }
    .trusted-by {
        color: white;
        display: block;
        font-family: $baseFontMono;
        font-size: 0.9rem;
        text-transform: uppercase;
        margin: auto;
        margin-top: 15px;

        @media(max-width: $screen-md){
            max-width: 300px;
        }

    }

}
