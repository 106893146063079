@import 'assets/scss/partials/vars';

footer {
    display: block;
    background: $spade-blue;
    color: $white;

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media(min-width: $screen-md){
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .blockwrap.row-1 {
        display: flex;
        height: 400px;
        padding: 50px 0 50px 0;
        background: $spade-blue;
        color: $off-white;

        @media(min-width: $screen-md){
            height: 312px;
            flex-direction: row;
        }

        .content {
            @media(min-width: $screen-md){
                flex-direction: row;
                justify-content: space-between;
                align-items: start;
            }
        }

        .option {
            display: block;
            width: 100%;
            text-align: left;
            line-height: 1.4rem;

            @media(min-width: $screen-md){
                width: 20%;
            }

            &.api-access {
                @media(min-width: $screen-md){
                    width: 60%;
                }
            }

            &.connect {
                margin: 0 0 24px 0px;
            }
            &.social {
                @media(min-width: $screen-md){
                    padding-left: 40px;
                }
            }

            &.security-privacy {
                @media(min-width: $screen-md){
                    padding-left: 40px;
                }
                @media(max-width: $screen-md){
                    margin-top: 20px;
                }
            }

            .footer-heading {
                display: block;
                background: none;
                color: $off-white;
                font-family: $baseFont;
                font-size: 1.1rem;
                letter-spacing: -0.75px;
            }
            button.btn-api-access {
                margin: 0px 0px 55px 0;
                cursor: pointer;
            }

            a.footer-link {
                display: block;
                margin: 2px 0 2px 0;
                color: $spade-light-blue;
                text-decoration: none;
                cursor: pointer;
            }

            link.footer-link {
                display: block;
                margin: 2px 0 2px 0;
                color: $spade-light-blue;
                text-decoration: none;
                cursor: pointer;
            }
        }
    }

    .blockwrap.row-2 {
        padding: 145px 0 40px;
        background: $spade-cobalt-blue;
        color: $off-white;

        .spade-logo {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1em;
            font-size: 1rem;
            position: initial;
            margin: 0 0 0 20px;

            svg {
                height: 75px;
                width: 75px;

                @media(max-width: $screen-md){
                    height: 65px;
                    width: 65px;
                }
            }
        }

        .soc-logo {
            height: 75px;
            width: 75px;
            margin: 0 20px 0 0;

            @media(max-width: $screen-md){
                width: 65px;
                height: 65px;
            }
        }

        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: baseline;

            p {
                font-family: $baseFont;
                font-size: 1.2rem;
                letter-spacing: -1.5px;

                @media(min-width: $screen-md){
                    font-size: 1.4rem;
                }
            }
        }
    }
}