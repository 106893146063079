@import '../../../assets/scss/partials/vars';

.privacy-terms {
    background: $white;
    color: $off-white;

    .header {
        // Temporary Fix to style header
        // TODO Understand and fix why this `.header` is unable to inherit from Header/style.css
        display: flex;
        align-items: baseline;
        justify-content: space-between;

        @media(min-width: $screen-md){
            // margin-top: 80px;
        }

        nav {
            ul {
                display: flex;
                list-style-type: none;
                font-family: $baseFont;

                li {
                    display: block;
                    margin: 0 10px;

                    a, button {
                        background: none;
                        color: $spade-blue;
                        font-family: $baseFont;
                        text-decoration: none;
                        font-size: 1.15rem;

                        &:hover {
                            color: $spade-cobalt-blue;
                            transition: all 0.35s linear;
                        }
                    }
                }
            }
        }
        button.access {
            display: block;
            padding: 3px 16px;
            margin-right: 20px;
            border: 1px solid $spade-blue;
            background: white;
            border-radius: 15px;
            cursor: pointer;
            color: $spade-blue;
            font-family: $baseFont;
            font-size: 1.15rem;
            text-decoration: none;
            &:hover {
                color: $white;
                background: $spade-blue;
                transition: all 0.35s linear;
            }
        }
    }

    .blockwrap.hero {
        margin-top: 50px;
        margin-bottom: 80px;

        @media(min-width: $screen-md){
            margin-top: 100px;
            margin-bottom: 110px;
        }

    }

    .block {

        text-align: left;

        h1 {
            display: block;
            margin: 0 0 30px;
            color: $spade-blue;
            font-family: $baseFont;
            font-size: 2rem;
            line-height: 1;
            letter-spacing: -1.5px;
            text-align: left;

            @media(min-width: $screen-xs){
                font-size: 3rem;
            }
        }

        a {
            color: $spade-blue;
        }

        a:hover {
            color: $spade-light-blue;
        }

        p {
            color: $spade-blue;
            font-family: $baseFont;
            font-size: 1rem;
            line-height: 1.3;
            text-align: left;
        }

        .tab {
            margin-left: 40px;
        }
    }
}