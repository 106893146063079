@import 'assets/scss/partials/vars';

.btn-general {
    display: inline-block;
    width: auto;
    margin: 40px 0 0 0;
    padding: 7px 16px;
    background: $off-white;
    border-radius: 15px;
    color: $spade-blue;
    font-family: $baseFontMono;
    font-size: 0.9rem;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.35s linear;

    &.blue {
        border: 1px solid $spade-blue;
        background: $spade-blue;
        color: $off-white;
        
        &:hover {
            border: 1px solid $spade-blue;
            background: $off-white;
            color: $spade-blue;
        }
    }

    &:hover {
        color: $spade-light-blue;
        background: $spade-blue;
    }
}