/* Animated background */
.fancybackground, .fancytext, .MuiFormLabel-root.Mui-focused {
    /*background: linear-gradient(230deg, #5f4dee, #50a3cf, #5f4dee);*/
    background: linear-gradient(226deg, #595cff, #b8c6ff, #3300cc, #b8c6ff, #595cff);
    background-size: 900vw 1000vh;
    background-attachment: fixed;

    -webkit-animation: BackgroundAnimation 59s ease infinite;
    -moz-animation: BackgroundAnimation 59s ease infinite;
    -o-animation: BackgroundAnimation 59s ease infinite;
    animation: BackgroundAnimation 59s ease infinite;
}

/* Text coloured according to the animated background */
.MuiFormLabel-root.Mui-focused, .fancytext {
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    color: #5a6be3;
}

@-webkit-keyframes BackgroundAnimation {
    0%{background-position:0% 28%}
    50%{background-position:100% 73%}
    100%{background-position:0% 28%}
}
@-moz-keyframes BackgroundAnimation {
    0%{background-position:0% 28%}
    50%{background-position:100% 73%}
    100%{background-position:0% 28%}
}
@-o-keyframes BackgroundAnimation {
    0%{background-position:0% 28%}
    50%{background-position:100% 73%}
    100%{background-position:0% 28%}
}
@keyframes BackgroundAnimation {
    0%{background-position:0% 28%}
    50%{background-position:100% 73%}
    100%{background-position:0% 28%}
}