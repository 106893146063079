@import 'assets/scss/partials/vars';

.form-api-access {
    display: flex;
    flex-direction: column;

    .content {
        max-width: 400px;

        @media(min-width: $screen-md) {
            max-width: 500px;
        }

        h1 {
            display: block;
            color: $off-white;
            padding-left: 10px;
            font-family: $baseFont;
            font-size: 1.75rem;
            letter-spacing: -1px;
            text-align: left;
        }
    }

    form {
        display: flex;
        flex-direction: column;
        width: 94%;
        margin: 0 auto;
    }
    label {
        display: block;
        margin: 2px 0 6px 0;
        color: $off-white;
        padding-left: 10px;
        font-family: $baseFont;
        font-size: 0.8rem;
        text-align: left;
    }

    .input-wrap {
        display: block;
        margin-bottom: 16px;
    }

    input {
        display: block;
        height: 30px;
        width: 100%;
        padding-left: 10px;
        color: $spade-blue;
        font-family: $baseFont;
        font-size: 1rem;
        border: 0;
        border-radius: 10px;
        outline: none;
        -webkit-box-shadow: none;
        box-shadow: none;

        @media(min-width: $screen-md) {
            max-width: 400px;
        }

        &::placeholder {
            color: $spade-blue;
            font-family: $baseFont;
            font-size: 0.8rem;
        }
    }

    textarea {
        height: 80px;
        width: 100%;
        padding: 10px;
        border: 0;
        border-radius: 10px;
        outline: 0;
        color: $spade-blue;
        font-family: $baseFont;
        font-size: 0.8rem;

        &::placeholder {
            color: $spade-blue;
            font-family: $baseFont;
            font-size: 0.8rem;
        }

        &:focus {
            outline: none;
            border-color: inherit;
            -webkit-box-shadow: none;
            box-shadow: none;
        }

    }

    button.submit {
        display: block;
        height: auto;
        width: 270px;
        margin: 16px auto 0;
        padding: 10px 20px;
        font-size: 1rem;
        
    }

    p.error-msg {
        display: block;
        width: 100%;
        padding-left: 10px;
        margin: 8px 0 0 0;
        color: $off-white;
        font-family: $baseFont;
        font-size: 0.8rem;
        text-align: left;
    }
}
