@import '../../../assets/scss/partials/vars';

.spade-logo {
    z-index: 3;
    position: absolute;
    display: block;
    height: 28px;
    top: 25px;
    left: 20px;

    .cls-1 {
        //fill:#fff;
        fill: $spade-blue;
        transition: all 0.35s linear;
    }

    @media(min-width: $screen-md){
        position: relative;
        top: 0;
        left: 0;
        height: 35px;
        margin: 0px 0 0 20px;
        
    }
}   
.home .spade-logo.open {
    .cls-1 {
        fill: $off-white;
    }
}
.about .spade-logo, .solutions .spade-logo {
    .cls-1 {
        fill: $off-white;
    }
}