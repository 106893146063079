@import 'assets/scss/partials/vars';

.blockwrap.get-started {
    padding-top: 90px;
    padding-bottom: 90px;
    background: $spade-cobalt-blue;
    color: $off-white;

    .content {
        text-align: left;

        h3 {
            font-family: $baseFont;
            font-size: 3.5rem;
            text-align: left;
            letter-spacing: -1.5px;

            @media(min-width: $screen-md) {
                font-size: 3.2rem;
            }

        }
    }
}