@import 'assets/scss/partials/vars';

.about {
    background: $spade-blue;
    color: $off-white;

    .blockwrap.hero {
        margin-top: 170px;
        margin-bottom: 80px;
        overflow: visible;

        @media(min-width: $screen-md){
            margin-top: 200px;
            margin-bottom: 110px;
        }
    }

    .block-1 {
            
        text-align: left;

        @media(min-width: $screen-md){
            width: 64%;
        }

        h1 {
            display: block;
            margin: 0 0 30px;
            color: $off-white;
            font-family: $baseFont;
            font-size: 3.4rem;
            letter-spacing: -1.5px;
            line-height: 1;
            text-align: left;
    
            @media(min-width: $screen-xs){
                font-size: 3.8rem;
            }
        }

        p {
            color: $off-white;
            font-family: $baseFont;
            font-size: 1rem;
            line-height: 1.3;
            text-align: left;
        }
        button.btn-general {
            width: 220px;
        }

    }
    // .content {
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;

    //     @media(min-width: $screen-md){
    //         flex-direction: row;
    //         justify-content: space-between;
    //     }
    // }

}