@import '../../../assets/scss/partials/vars';

.backdrop {
    z-index: 3;
    position: absolute;
    top: 0;
    bottom: 0;
    display: block;
    //height: 100vh; 
    width: 100%;
    background: $off-white;
    opacity: 0.75;
    transition: all 0.8s linear;
    cursor: pointer;

    span {
        position: fixed;
        top: 20px;
        right: 20px;
        height: 30px;
        width: 30px;
        color: #000;
        font-family: $baseFont;
        font-size: 2rem;

        span.first {
            position: absolute;
            display: block;
            left: 0;
            width: 100%;
            height: 2px;
            background: #717170;
            transform: rotate(45deg);
        }
        span.second {
            position: absolute;
            display: block;
            left: 0;
            width: 100%;
            height: 2px;
            background: #717170;
            transform: rotate(-45deg);
        }
    }
}


