@import '../../assets/scss/partials/vars';

.home .header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    @media(min-width: $screen-md){
        // margin-top: 80px;
    }

    nav {
        ul {
            display: flex;
            list-style-type: none;
            font-family: $baseFont;

            li {
                display: block;
                margin: 0 10px;

                a, button {
                    background: none;
                    color: $spade-blue;
                    font-family: $baseFont;
                    text-decoration: none;
                    font-size: 1.15rem;

                    &:hover {
                        color: $spade-cobalt-blue;
                        transition: all 0.35s linear;
                    }
                }
            }
        }
    }
    button.access {
        background: none;
        display: block;
        padding: 3px 16px;
        margin-right: 20px;
        border: 1px solid $spade-blue;
        border-radius: 15px;
        cursor: pointer;
        color: $spade-blue;
        font-family: $baseFont;
        font-size: 1.15rem;
        text-decoration: none;
        &:hover {
            background-color: $spade-blue;
            border: 1px solid $spade-blue;
            color: $off-white;
            transition: all 0.35s linear;
        }
    }
}

.about .header, .solutions .header {
    align-items: baseline;

    nav ul li a, nav ul li button {
        background: none;
        color: $off-white;
        font-family: $baseFont;
        font-size: 1.15rem;
        cursor:pointer;

        &:hover {
            color: $spade-light-blue;
            transition: all 0.35s linear;
        }
    }

    button.access {
        display: block;
        padding: 3px 16px;
        margin-right: 20px;
        border: 1px solid $off-white;
        border-radius: 15px;
        cursor:pointer;
        color: $spade-blue;
        font-family: $baseFont;
        font-size: 1.15rem;
        text-decoration: none;
        &:hover {
            color: $spade-light-blue;
            background: $spade-blue;
            transition: all 0.35s linear;
            border-color: $spade-blue;
        }

    }
}