@import 'assets/scss/partials/vars';

.blockwrap.our-team {
    background: $spade-light-grey;
    color: $spade-blue;
    padding-bottom: 90px;

    hr {
        border-top: 1px solid $spade-blue;
        margin: 0 0 20px 0;
    }
    h2 {
        color: $spade-blue;
        font-size: 1.8rem;
        letter-spacing: -1.5px;
        text-align: left;
        padding-bottom: 60px;

        @media(min-width: $screen-md){
            padding-bottom: 60px;
        }
    }

    .teamate {
        display: flex;
    }
    .details-wrap {
        display: flex;
        flex-direction: column;
        margin-top: 50px;
        border-top: 1px solid $spade-blue;

        @media(min-width: $screen-md){
            flex-direction: row;
            justify-content: space-evenly;
        }

        .infobox {
            display: block;
            width: 100%;
            margin-top: 20px;
            margin-bottom: 16px;
            text-align: left;

            @media(min-width: $screen-md){
                width: 36%;
            }

            .img-title-wrap {
                display: flex;
                flex-direction: row;
                align-items: flex-end;

                img {
                    display: block;
                    max-width: 75px;
                    margin-right: 20px;
                }

                h3 {
                    display: block;
                    color: $spade-blue;
                    font-family: $baseFont;
                    font-size: 1.5rem;
                    letter-spacing: -1px;
                }

            }
            p {
                display: block;
                width: 100%;
                margin-top: 20px;
                color: $spade-blue;
                font-family: $baseFont;
                font-size: 1rem;
                line-height: 1.3;
            }
        }
    }
}