@import 'assets/scss/partials/vars';

.blockwrap.how-spade-works {
    //background: $spade-light-grey;
    color: $spade-blue;

    .content {
        display: flex;
        flex-direction: column;
        margin-top: 90px;
        margin-bottom: 80px;
       
        @media(min-width: $screen-md){
            margin-top: 120px;
        }

        img {
            display: block;
            width: 100%;
            margin: 18px auto 24px;
        }
    }

    h2 {
        display: block;
        width: 100%;
        margin: 16px 0 0 0;
        padding: 12px 0 12px 0;
        color: $spade-blue;
        border-top: 1px solid $spade-blue;
        font-family: $baseFont;
        font-size: 2.1rem;
        line-height: 1.1;
        letter-spacing: -1.5px;
        text-align: left;

        @media(min-width: $screen-md){
            font-size: 2.1rem;
        }

    }

    .info-wrap {
        display: flex;
        flex-direction: column;

        @media(min-width: $screen-md){
            flex-direction: row;
        }

        ul {
            display: flex;
            flex-direction: column;

            @media(min-width: $screen-md){
                flex-direction: row;
                justify-content: space-between;
            }

            li {
                display: flex;
                flex-direction: row;
                margin: 4px 0 24px 0;
                text-align: left;

                @media(min-width: $screen-md){
                    width: 31%;
                }

                span {
                    display: block;
                    margin-right: 4px;
                    font-weight: bold;
                }

                p {
                    padding-right: 12px;
                    line-height: 1.3;

                    @media(min-width: $screen-md) {
                        padding: 0;
                    }
                }
            }
        }
    }
    
}