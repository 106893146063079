@import 'assets/scss/partials/vars';

.banner {
    z-index: 10;
    position: fixed;
    top: 0;
    display: block;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 20px 0 20px 0;
    background: $spade-blue;
    color: $white;
    font-family: $baseFontMono;
    font-size: 0.9rem;

    @media(min-width: $screen-md){
        height: 50px;
        padding: 0;
    }

    .container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        a {
            display: inline-block;
            color: $spade-light-grey;
            outline: none;
            border: 0;
            text-decoration: none;
        }

        // button {
        //     position: absolute;
        //     right: 5px; 
        //     top: 5px;
        //     display: flex;
        //     align-items: center;
        //     justify-content: center;
        //     height: 30px;
        //     width: 30px;
        //     background: none;
        //     color: $white;
        //     font-family: $baseFontMono;
        //     cursor: pointer;

        //     @media(min-width: $screen-md){
        //         right: 20px; 
        //         top: 10px;
        //     }

        // }
        button {
            position: absolute;
            right: 2px;
            height: 20px;
            width: 20px;
            background: none;
            color: #000;
            font-family: $baseFont;
            font-size: 2rem;
            cursor: pointer;

            @media(min-width: 400px){
                right: 6px;
            }
            @media(min-width: $screen-md){
                //top: 10px;
            }

    
            span.first {
                position: absolute;
                display: block;
                left: 0;
                width: 100%;
                height: 2px;
                background: $off-white;
                transform: rotate(45deg);
            }
            span.second {
                position: absolute;
                display: block;
                left: 0;
                width: 100%;
                height: 2px;
                background: $off-white;
                transform: rotate(-45deg);
            }
        }
    }

}