    
@font-face {
    font-family:'MessinaSans';
    src: url('../../fonts/MessinaSans_Web_Regular/MessinaSansWeb-Regular.woff') format('woff2'),
         url('../../fonts/MessinaSans_Web_Regular/MessinaSansWeb-Regular.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family:'MessinaSansMono';
    src: url('../../fonts/MessinaSansMono_Web_Regular/MessinaSansMonoWeb-Regular.woff2') format('woff2'),
         url('../../fonts/MessinaSansMono_Web_Regular/MessinaSansMonoWeb-Regular.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}