@import '../../../assets/scss/partials/vars';

.mobile-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
}

button.button-menu {
    z-index: 3;
    position: absolute;
    right: 20px;
    top: 24px;
    display: flex;
    justify-content: center;
    width: 30px;
    background: none;
    cursor: pointer;
    
}