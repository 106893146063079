@import 'assets/scss/partials/vars';

.blockwrap.developer-focused {
    background: $spade-cobalt-blue;
    color: $off-white;

    .content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 120px;
        margin-bottom: 100px;

        @media(min-width: $screen-md){
            flex-direction: row;
            align-items: center;
        }

        .side {
            width: 100%;
            text-align: left;

            @media(min-width: $screen-md){
                width: 50%;
            }

            h2 {
                font-family: $baseFont;
                font-size: 3.8rem;
                letter-spacing: -1.5px;
                line-height: 1;
                text-align: left;
                
                @media(min-width: $screen-sm){
                    font-size: 4rem;
                }
                @media(min-width: $screen-md){
                    font-size: 4.2rem;
                }
                
            }
    
            p{
                margin: 40px 0 34px 0;
                text-align: left;
                

                @media(min-width: $screen-md){
                    max-width: 325px;
                    font-size: 1.1rem;
                    line-height: 1.3;
                }
            }

            .item-wrap {
                display: flex;
                flex-direction: row;
                max-width: 500px;

                @media(min-width: $screen-md){
                    max-width: 325px;
                }

                .item {
                    display: block;
                    width: 50%;
                    text-align: left;

                    img {
                        display: inline-block;
                        height: 81px;
                        
                    }

                    p {
                        margin: 16px 0 0 0;
                        font-size: 0.8rem;

                        @media(min-width: $screen-md){
                            font-size: 0.9rem;
                        }
                    }
                }
            }
            a.btn-general {
                width: 170px;
            }
        }

        .side.b {
            width: 100%;
            margin-top: 70px;

            @media(min-width: $screen-md){
                margin: 0;
                width: 50%;
            }

            img {
                display: block;
                width: 100%;
                margin: 0 auto;

                @media(min-width: $screen-md){
                    max-width: 420px;
                    margin: 0;
                }
            }
        }

       
    }
}