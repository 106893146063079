/* VARS - WEBSITE =====================================
======================================================= */
$white: #FFFFFF;
$black: #000000;
$spade-blue: rgba(89, 92, 255, 1);
$spade-cobalt-blue: rgba(51, 0, 203, 1);
$spade-light-blue: rgba(183, 197, 255, 1);
$off-white: rgba(252, 251, 250, 1);
$spade-light-grey: rgba(229, 229, 229, 1);
// General

// FONTS ============================================== */
$baseFont: 'MessinaSans',"Open Sans",sans-serif;
$baseFontMono: 'MessinaSansMono', monospace, sans-serif;
/* BREAKPOINTS ======================================== */

// smartphones (portrait view)
$screen-xs: 374px;

// Small tablets and large smartphones (landscape view)
$screen-sm: 576px;

// Small tablets (portrait view)
$screen-md: 768px;

// Tablets and small desktops
$screen-lg: 1024px;

// Large tablets and desktops
$screen-xl: 1200px;

// Super large
$screen-max: 1600px;
