@import '../../../assets/scss/partials/vars';

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media(min-width: $screen-md){
        margin-top: 90px;
    }

    nav {
        ul {
            display: flex;
            list-style-type: none;
            font-family: $baseFont;

            li {
                display: block;
                margin: 0 10px;
                &.active {
                    text-decoration: underline;
                }

                a, button {
                    color: $spade-blue;
                    text-decoration: none;

                    &:hover {
                        color: $spade-cobalt-blue;
                        transition: all 0.35s linear;
                    }
                }
            }
        }
    }
}