@import '../../assets/scss/partials/vars';

.home {
    display: block;
    color: $spade-blue;
    background: $white;
    
    .blockwrap.hero {
        margin-top: 50px;
        margin-bottom: 80px;
        background: $white;
        overflow: visible;

        @media(min-width: $screen-md){
            margin-top: 100px;
            margin-bottom: 110px;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media(min-width: $screen-md){
            flex-direction: row;
            justify-content: space-between;
        }

        .block-1 {
            text-align: left;

            @media(min-width: $screen-md){
                width: 50%;
            }

            h1 {
                display: block;
                margin: 0 0 30px;
                color: $spade-blue;
                font-family: $baseFont;
                font-size: 3.4rem;
                letter-spacing: -1.5px;
                line-height: 1;
                text-align: left;
        
                @media(min-width: $screen-xs){
                    font-size: 3.8rem;
                }
            }
    
            p {
                color: $spade-blue;
                font-family: $baseFont;
                font-size: 1rem;
                line-height: 1.3;
                text-align: left;

                @media(min-width: $screen-md){
                    width: 90%;
                }
            }
            button.btn-general {
                width: 190px;
                @media(min-width: $screen-md){
                    margin-bottom: 12px;
                }
            }

        }
        .block-2 {
            display: flex;
            justify-content: center;
            margin-top: 40px;

            @media(min-width: $screen-md){
                width: 50%;
                margin-top: 0px;
            }

            img {
                display: block; 
                width: auto;

                @media(min-width: $screen-xs){
                    max-height: 420px;
                }

            }
        }
     

    }
    
}