@import 'assets/scss/partials/vars';

.blockwrap.our-investors {
    background: $off-white;
    padding-top: 90px;
    padding-bottom: 90px;

    @media(min-width: $screen-md){
        padding-top: 90px;
        padding-bottom: 90px;
    }

    .border-wrap {
        display: flex;
        flex-direction: column;
        border-top: 1px solid $spade-blue; 
        //padding-top: 18px; 

        @media(min-width: $screen-md){
            flex-direction: row;
            justify-content: space-between;
        }

        h2 {
            margin-top: 18px;
            margin-bottom: 18px;
            color: $spade-blue;
            font-size: 2.1rem;
            line-height: 1.1;
            letter-spacing: -1.5px;
            text-align: left;

        }
    }

    .options-wrap {
        display: block;
        width: 100%;
        text-align: left;

        @media(min-width: $screen-md){
            width: 66%;
        }
        
        a {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 16px 20px 0;
            border-bottom: 1px solid $spade-blue;
            color: $spade-blue;
            font-family: $baseFont;
            font-size: 1.8rem;
            letter-spacing: -1.5px;
            line-height: 1.3;
            text-align: left;
            text-decoration: none;

            @media(min-width: $screen-md){
                font-size: 2.1rem;
                line-height: 1.1;
                letter-spacing: -1.5px;
            }
        }
       
    }
}