@import 'assets/scss/partials/vars';

.blockwrap.about-content {
    padding-bottom: 60px;
    background: $off-white;
    color: $spade-blue;

    .content {
        margin-top: 110px;

        @media(min-width: $screen-md){
            margin-top: 125px
        }

        .content-block {
            display: flex;
            flex-direction: column;
            padding-top: 20px;
            //padding-bottom: 40px;
            border-top: 1px solid $spade-blue;

            @media(min-width: $screen-md){
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding-top: 50px;
                padding-bottom: 50px;
            }
           
            .block-1 {
                width: 100%;

                @media(min-width: $screen-md) {
                    width: 50%;
                }

                h2 {
                    margin-bottom: 30px;
                    color: $spade-blue;
                    font-family: $baseFont;
                    font-size: 2.1rem;
                    line-height: 1.1;
                    letter-spacing: -1.5px;

                    @media(min-width: $screen-md){
                        margin-bottom: 24px;
                        font-size: 2.1rem;
                    }

                }
                p{
                    margin: 16px auto;
                    padding: 0 20px 0 0;
                    color: $spade-blue;

                    @media(min-width: $screen-md){
                        width: 87%;
                        margin: 18px 0 18px 0;
                        
                    }
                }
            }
    
            .block-2 {
                width: 100%;
                margin: 20px 0 0px 0;
                padding-bottom: 40px;
                text-align: left;

                @media(min-width: $screen-md) {
                    margin: 0;
                    padding-bottom: 0px;
                    padding-left: 40px;
                    width: 50%;
                }

                img {
                    display: inline-block;
                    width: 100%;
    
                    @media(min-width: $screen-md){
                        width: auto;
                        max-height: 480px;
                        margin: 0;
                    }
                }
            }
        }
        .content-block.row-2 {

            @media(min-width: $screen-md){
                flex-direction: row-reverse;
            }

            .block-1 {
                position: relative;

                @media(min-width: $screen-md){
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    padding-left: 40px;
                }
                // h2 {
                //     @media(min-width: $screen-md){
                //         position: absolute;
                //         top: 0;
                //     }
                   
                // }
                p {
                    @media(min-width: $screen-md){
                        width: 100%;
                    }
                }
            }
            .block-2 {
                @media(min-width: $screen-md){
                    padding-left: 0px;
                }
            }
        }

       
    }
}