@import 'assets/scss/partials/vars';

.blockwrap.solutions-info {
    padding-top: 90px;
    padding-bottom: 90px;
    background: $off-white;
    color: $spade-blue;
    
    
    .content-block {
        position: relative;
        display: flex;
        flex-direction: column;
        padding-top: 40px; 
        padding-bottom: 40px;
        border-top: 1px solid $spade-blue;

        @media(min-width: $screen-md){
            flex-direction: row;
            align-items: center;
            padding-top: 50px; 
            padding-bottom: 50px;
            
        }
        
        &.reverse {
            @media(min-width: $screen-md){
                flex-direction: row-reverse;
            }
        }
        
        &.row-1 {
            img {
                @media(min-width: $screen-md){
                   padding-left: 40px;
                   padding-right: 0;
                }
            }
           
        }
        &.row-2 {
            .info-wrap {
                @media(min-width: $screen-md){
                    margin-left: 40px;
                 }
            }
            img {
                @media(min-width: $screen-md){
                   padding-right: 40px;
                }
            }
        }

        img {
            width: 100%;

            @media(min-width: $screen-md){
                width: 40%;
            }
        }
       
        .info-wrap {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 100%;
            text-align: left;

            @media(min-width: $screen-md){
                width: 50%;
                justify-content: flex-end;
                padding-bottom: 20px;
            }

            h2 {
                position: relative;
                margin: 20px 0 30px;
                font-family: $baseFont;
                font-size: 2.1rem;
                line-height: 1.1;
                letter-spacing: -1.5px;

                @media(min-width: $screen-md) {
                    margin: 0 0 50px;
                }
            }
            p {
                font-family: $baseFont;
                font-size: 1.2rem;
                letter-spacing: -0.5px;
                
                @media(min-width: $screen-md) {
                    width: 85%;
                }
            }

            ul {
                display: block;
                margin-top: 20px;
                padding: 0 0 0 16px;
                list-style-type: circle;

                li {
                    font-family: $baseFont;
                    font-size: 0.9rem;
                    line-height: 1.4;
                }
            }
        }
    }
}