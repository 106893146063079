@import 'assets/scss/partials/vars';

html.open-menu {
    overflow: hidden;
}
.mobile-menu-wrap {
    z-index: 2;
    display: block;
    position: absolute;
    width: 100%;
    height: calc(100vh);
    top: 0;
    background-color: $spade-blue;
    color: $white;
    transform: translateY(-100vh);
    transition: all .35s ease-out;

   &.open {
        top: 0;
        transform: translateY(0vh);
        opacity: 1;
        height: calc(100vh);
    }


    nav {
        display: block;
        width: 100%;
        margin: 0 auto;
        padding: 0 0 0 20px;

        ul { 
            list-style-type: none;
            padding-top: 110px;
            line-height: 1.1;

            li a, li button {
                display: block;
                background: none;
                color: $off-white;
                text-decoration: none;
                cursor: pointer;

                &.main {
                    font-size: 2rem;
                }
                &.access {
                    margin-top: 45px;
                    font-size: 2rem;
                }
                &.api {
                    margin-top: 45px;
                    margin-bottom: 45px;
                }
                &.hello{
                    margin-top: 45px;
                    margin-bottom: 45px;
                }
                &.contact {
                    margin-bottom: 20px;
                }
                &.menu-terms{
                    margin-top: 45px;
                }
            }
        }
    }
}