@import 'assets/scss/partials/vars';

.nav-dots {
    height: 30px;
    width: 10px;
    background: none;
    //width: 40px;
   .cls-1{ fill: $spade-blue; stroke: $spade-blue; stroke-width: 2px;}

   path {
    fill: $spade-blue;
   }

   &.open {
    background: none;
    .cls-1{ fill: none; stroke: $white; stroke-width: 2px;}
   }
}

.nav-dots.about, .nav-dots.solutions {
    background: none;
    .cls-1{ fill: $white;  stroke: $white; stroke-width: 2px;}
    &.open {
        .cls-1{ fill: none; stroke: $white; stroke-width: 2px;}
       }
}