@import 'assets/scss/partials/vars';

.blockwrap.purpose-built-solutions {
    display: block;
    background: $off-white;
    color: $spade-blue;

    .content {
        flex-direction: column;
        align-items: start;
        margin-top: 120px;
        margin-bottom: 80px;

        @media(min-width: $screen-md){
            flex-direction: row;
            justify-content: space-between;
        }

        .section-title {
            display: block;
            width: 100%;
            margin: 0 auto 50px;
            text-align: left;

            @media(min-width: $screen-md){
                width: 33%;
            }

            h2 {
                font-family: $baseFont;
                font-size: 2.4rem;
                letter-spacing: -1px;

                @media(min-width: $screen-sm){
                    font-size: 3rem;
                }

                @media(min-width: $screen-md){
                    font-size: 2.1rem;
                    line-height: 1.1;
                }

            }

            a.btn-general {
                width: 190px;
            }
        }

        .section-info {
            width: 100%;
            text-align: left;

            @media(min-width: $screen-md){
                width: 66%;
            }

            .info-wrap {
                display: flex;
                flex-direction: column;
                
                @media(min-width: $screen-md){
                    flex-direction: row;
                    
                }

                .info {
                    width: 100%;
                    font-family: $baseFont;

                    @media(min-width: $screen-md){
                        width: 50%;
                        margin: 0px 6px;
                    }

                    img {
                        display: block;
                        width: auto;
                        max-height: 250px;
                        margin: 0 auto;

                        @media(min-width: $screen-md){
                            width: auto; 
                            height: 300px;
                            max-height: 295px;
                            margin: 0;
                        }
                    }

                    h3 {
                        display: block;
                        margin: 16px 0 20px;
                        font-family: $baseFont;
                        font-size: 1.5rem;
                        letter-spacing: -0.75px;
    
                        @media(min-width: $screen-md){
                            font-size: 1.5rem;
                        }
                    }
                    p {
                        margin: 12px 0 40px;
                        font-size: 1rem;
                        line-height: 1.3;

                        @media(min-width: $screen-md){
                            font-size: 1.1rem;
                            //line-height: 1.3;
                        }
                        
                    }
                }

            }

            
        }
    }
}