@import 'assets/scss/partials/vars';

.arrow-angled {
    height: 18px;

    @media(min-width: $screen-md){
        height: 22px;
    }

    .cls-1 {
        fill: $spade-blue;
    }
}