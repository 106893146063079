@import 'assets/scss/partials/vars';

.blockwrap.join-us {
    background: $off-white;
    color: $spade-blue;
    padding-bottom: 90px;
    

    hr {
        margin: 0 0 20px 0;
        border-top: 1px solid rgb(89, 92, 255);
    }

    .join-us-section {
        display: flex;
        text-align: left;
        flex-direction: column;

        @media(min-width: $screen-md) {
            flex-direction: row;
            justify-content: space-between;
        }

        .block-1 {
            @media(min-width: $screen-md) {
                width: 33%;
            }

            h2 {
                font-family: $baseFont;
                font-size: 2.1rem;
                line-height: 1.1;
                letter-spacing: -1.5px;
                text-align: left;
            }
        }
       
        .block-2 {

            @media(min-width: $screen-md) {
                width: 66%;
            }

            h2 {
                margin: 30px 0 20px;
                font-family: $baseFont;
                font-size: 2.1rem;
                line-height: 1.1;
                letter-spacing: -1.5px;
                
                @media(min-width: $screen-md) {
                    width: 66%;
                    margin: 0px 0 20px 0;
                }
            }

            p {
                line-height: 1.3;
                font-family: $baseFont;
                font-size: 1rem;
                text-align: left;

                @media(min-width: $screen-md) {
                    width: 85%;
                }
            }
        }
        
    }
}